import { Component, OnInit } from '@angular/core';
import {Message} from 'primeng/primeng';
import { Router } from '@angular/router';
import { AuthService } from './../core/services/auth.service';
import { ThemeService } from './../core/services/theme.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';

export interface LoginConfig {
  "theme": string;
  "welcomeText": string;
  "title": string;
  "subtitle": string;
  "background": string;
  "headerIcon": string;
  "accentColor": string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  protected inputEmail: string;
  protected inputPassword: string;

  public config: LoginConfig;
  public msgs: Message[] = [];

  private defaultBackgroundUrl = "assets/images/drill_background.png";

  public constructor(
    private authService: AuthService,
    private themeService: ThemeService,
    private router: Router,
  ) {}

  public async ngOnInit(): Promise<any>{
    // call service for get loginconfig
    await this.authService.getLoginConfig(window.location.hostname)
      .subscribe((config: LoginConfig): void => {
        this.themeService.changeTheme(config.theme);
        this.config = config;
      });
  }

  public async onLogin(): Promise<any> {
    await this.authService.login(this.inputEmail, this.inputPassword)
      .subscribe((res: any): void => {
        if (res.success) {
          // Redirect the account
          this.router.navigate([""]);
        }
        else {
          this.showErrorViaMessages();
        }
      },
      (err): void => {
        const errorMessage = err && err.error ? err.error : (err && err.error.message) ? err.error.message : null;
        this.showErrorViaMessages(errorMessage);
      });
  }

  private showErrorViaMessages(error = 'Incorrect username or password'): void {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: 'Authentication error: ', detail: error });
  }

  private getBackgroundUrl(): string {
    return (this.config && this.config.background) ? this.config.background : this.defaultBackgroundUrl;
  }

  public getBodyStyle(): any {
    return {
      "background-image": `url("${this.getBackgroundUrl()}")`
    };
  }

}
