<!DOCTYPE html>
<html>

<head>
    <script>document.write('<base href="' + document.location + '" />');</script>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <title>Vixion App</title>
    <!-- <link rel="stylesheet" type="text/css" href="../theme/theme-blue-light.css">
    <link rel="stylesheet" type="text/css" href="../layout/css/layout-blue-light.css">
    <link rel="stylesheet" type="text/css" href="./grid.css"> -->
</head>
<span *ngIf="config; else loading">
    <body [ngStyle]="getBodyStyle()" class="login-body">
        <div class="body-container" [style.border-top-color]="config.accentColor">
            <div class="ui-g">
                <div class="ui-g-12 ui-lg-8 left-side">
                    <img [src]="config.headerIcon" alt="login-layout" class="logo" style="width:90px"/>
                    <h1>{{config.welcomeText ? config.welcomeText : ('login.welcomeText' | translate)}}</h1>
                    <h3>{{config.title ? config.title : ('login.title' | translate)}}</h3>
                    <p>{{config.subtitle ? config.subtitle : ('login.subtitle' | translate)}}</p>
                </div>
                <div class="ui-g-12 ui-lg-4 right-side">
                    <div class="login-wrapper">
                        <div class="login-container">
                            <span translate class="title">login.form.title</span>

                            <div class="ui-g ui-fluid">
                              <div class="ui-g-8">
                                <input type="text" autocomplete="off" [placeholder]="'login.form.username-placeholder' | translate" class="ui-inputtext ui-widget" [(ngModel)]="inputEmail" (keyup.enter)=onLogin()/>
                              </div>
                              <div class="ui-g-8">
                                <input type="password" autocomplete="off" [placeholder]="'login.form.password-placeholder' | translate" class="ui-inputtext ui-widget" feedback="true" [(ngModel)]="inputPassword" (keyup.enter)=onLogin()/>
                              </div>
                              <div class="ui-g-12 error-msg">
                                <p-messages [value]="msgs"></p-messages>
                              </div>
                              <div class="ui-g-6">
                                <button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="onLogin()">
                                  <span translate class="ui-button-text ui-c">
                                    login.form.button
                                  </span>
                                </button>
                              </div>
                              <div class="ui-g-6 password-container">
                                  <!-- <a href="#">Forgot Password?</a> -->
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
  </span>
  <ng-template #loading>
    <body>
        <div class="spinner" title="Loading...">
            <p-progressSpinner [style]="{width: '10rem', height: '15rem'}" strokeWidth="5"></p-progressSpinner>
        </div>
    </body>
  </ng-template>
</html>
