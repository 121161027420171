<html>
    <body class="exception-body error-body">
        <div class="exception-container">
          <img src="assets/layout/images/logo-dark.png" alt="apollo-layout" />
          <h1>{{ 'api-down.title' | translate }}</h1>
          <p class="pre-wrap">
            {{ 'api-down.message' | translate }}
          </p>
        </div>
    </body>
</html>
