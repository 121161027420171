import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable }               from 'rxjs/Observable';
import { tap, map, share, catchError } from 'rxjs/operators';
import { BehaviorSubject, of, from } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';

import { environment }  from './../../../environments/environment';
import { Account } from '../interfaces/account.interface';
import { RefreshToken } from '../interfaces/refreshToken.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class AuthService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private options = { headers: this.headers, withCredentials: true };

  private loggedAccount: Account;
  private $loggedAccount: Observable<any> = this.http.get(`${environment.apiV2Url}/auth/account`).pipe(
    tap((account)=>this.setAccount(account)),
    share()
  );
  public isLoggedIn: boolean = null;

  private apiDown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokenAccessError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokenAccessErrorMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private supportedLanguages = ['en', 'es', 'zh', 'de', 'it', 'fr'];

  private _isRefreshing = false;

  public constructor(
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
  ) {}

  get isRefreshing() {
    return this._isRefreshing;
  }

  set isRefreshing(value: boolean) { 
    this._isRefreshing = value;
  }

  public setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  public static getToken(): string {
    return localStorage.getItem("token");
  }

  public getTokenAsync(): Observable<string> {
    const token = AuthService.getToken();
    return of(token);
  }

  private setAccount(account: any): void {
    if (account && account.idAccount != null) {
      this.loggedAccount = account;
      this.isLoggedIn = true;
      this.setLanguage();
    }
  }

  private setLanguage(): void {
    try {
      const langCode = this.loggedAccount['language']['code'];
      if (this.supportedLanguages.includes(langCode)) {
        this.translateService.use(langCode);
      } else {
        this.translateService.use('en');
      }
    } catch (error) {
      this.translateService.use('en');
    }
  }

  public isLoggednIn(): Observable<boolean> {
    if (this.isLoggedIn !== null) {
      return of(this.isLoggedIn);
    } else {
      return this.$loggedAccount.pipe(
        catchError((error)=>{
          this.setApiDown(true);
          throw error;
        }),
        map((account)=> {
          return account && account.idAccount != null;
        }),
        tap((res): void => {
          this.setApiDown(false);
          this.isLoggedIn = res;
        }));
    }
  }

  private setApiDown(apiDown: boolean): void {
    this.apiDown.next(apiDown);
  }

  public isApiDown(): Observable<boolean> {
    return this.apiDown.asObservable();
  }

  public logout(): void {
    this.isLoggedIn = false;
    localStorage.removeItem("token");
    // this.clearTokenHeader();
    window['_paq'].push(["deleteCookies"]);
    this.router.navigate(["login"]);
  }

  addTokenHeader(req: HttpRequest<any>) {
    const token = AuthService.getToken() || ''
  
    return req.clone({headers: req.headers.set('x-access-token', token)});
  }

  public refreshToken() { 
    return this.http.get<RefreshToken>(`${environment.apiV2Url}/refresh-token`, {withCredentials: true});
  }

  public login(username: string, password: string): any {
    return this.http.post(`${environment.apiV2Url}/authenticate`, JSON.stringify({ username: username, password: password, from: 'web', name: 'app' }), this.options).pipe(
      tap({
        next: (r: any): void => {
          if (r.success) {
            this.setAccount(r.account);
            const token = r.token;
            this.setToken(token);
          }
          else {
            this.isLoggedIn = false
          }
        },
        error: (err): void => {
          if(err.error.maintenance) {
            this.setApiDown(true);
          }
          this.isLoggedIn = false;
        },
      }),
    );
  }

  public ssoLogin(): Observable<boolean> {
    return this.http.post(`${environment.apiV2Url}/sso/oauth/authenticate_login`, JSON.stringify({ from: 'web', token: AuthService.getToken() }), this.options).pipe(
      tap({
        next: (res: any): Observable<boolean> => {
          if (res && res.account && res.account.idAccount != null) {
            this.setToken(res.token);
            this.loggedAccount = res.account;
            this.setLanguage();
            this.router.navigate([''], {queryParams: {}});
            return of(true);
          } else {
            this.ssoLoginError(res && res.message ? res.message : '');
            return of(false);
          }
        },
        error: (err): Observable<boolean> => {
          this.ssoLoginError(err);
          return of(false);
        },
      }),
    );
  }
  
  private ssoLoginError(err) {
    this.removeToken();
    const message: string = err.error.message ? err.error.message : '';
    this.tokenAccessError.next(true);
    this.tokenAccessErrorMessage.next(message);
  }

  private removeToken() {
    localStorage.removeItem("token");
    window['_paq'].push(["deleteCookies"]);
  }

  public isTokenAccessError(): Observable<boolean> {
    return this.tokenAccessError.asObservable();
  }

  public getTokenAccessErrorMessage(): Observable<string> {
    return this.tokenAccessErrorMessage.asObservable();
  }

  public getLoginConfig(hostname: string): Observable<any> {
    const params = new HttpParams()
      .set('hostname', hostname);
    return this.http.get(`${environment.apiV2Url}/login-config`,{ params: params });

  }
  // private clearTokenHeader(): void {
  //   this.options.headers.delete('x-access-token');
  // }

  // private clearContentHeader(): void {
  //   this.options.headers.delete('Content-Type');
  // }

  // private setTokenHeader(token: string): void {
  //   if(!this.options.headers.has('x-access-token')) {
  //     this.options.headers.append('x-access-token', token);
  //   }
  // }

  public getAccount(): Observable<any>{
    if (this.loggedAccount){
      return of(this.loggedAccount);
    } else {
      return this.$loggedAccount;
    }
  }

}
