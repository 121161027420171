import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/services/auth.guard';
import { Angulartics2Module } from 'angulartics2';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false, // <-- debugging purposes only
    }),
    Angulartics2Module.forRoot(),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
