<!DOCTYPE html>
<html>

<head>
    <script>document.write('<base href="' + document.location + '" />');</script>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <title>Vixion App</title>
</head>
    <body *ngIf="config" [ngStyle]="getBodyStyle()" class="login-body">
        <div class="body-container" [style.border-top-color]="config.accentColor">
            <div class="ui-g">
                <div class="ui-g-12 ui-lg-8 left-side">
                    <img [src]="config.headerIcon" alt="login-layout" class="logo" style="width:90px"/>
                    <h1>{{config.welcomeText ? config.welcomeText : ('login.welcomeText' | translate)}}</h1>
                    <h3>{{config.title ? config.title : ('login.title' | translate)}}</h3>
                    <p>{{config.subtitle ? config.subtitle : ('login.subtitle' | translate)}}</p>
                </div>
                <div class="ui-g-12 ui-lg-6 right-side">
                    <div class="login-wrapper">
                        <div class="login-container">
                            <div class="ui-g ui-fluid">
                                <div class="ui-g-12 error-msg">
                                    <p-messages [value]="msgs"></p-messages>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</html>